<template>
  <div class="eventDetail_box">
    <!-- 加载动画 -->
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>
    <Header ref="Header"></Header>
    <div class="eventDetail_cont">
      <div class="info_top clearfix">
        <!-- 有赛事信息的图片 -->
        <img
          :src="eventInfo.images | dealEventimg"
          class="info_img"
          alt=""
          v-if="eventInfo.images"
        />
        <!-- 无赛事信息图片 -->
        <img src="" class="info_img" alt="" v-else />
        <div class="right_info">
          <span class="info_title text_over" v-if="eventInfo.title" v-cloak>{{
            eventInfo.title
          }}</span>
          <div class="info_show">
            <div v-if="!eventInfo.isEnroll">
<template  v-cloak>
              <span class="info_time">{{ eventInfo.labels }}</span>
            </template>
            </div>
            <div v-else>
            <template v-if="eventInfo.status == -1" v-cloak>
              <span class="info_time">赛事已关闭：{{ coutime }}</span>
            </template>
            <template v-if="eventInfo.status == 0" v-cloak>
              <span class="info_time">距报名开始：{{ coutime }}</span>
            </template>
            <template v-else-if="eventInfo.status == 1" v-cloak>
              <span class="info_time">距报名结束：{{ coutime }}</span>
            </template>
            <template v-else-if="eventInfo.status == 2" v-cloak>
              <span class="info_time">报名已结束</span>
            </template>
            <template v-else-if="eventInfo.status == 3" v-cloak>
              <span class="info_time">即将开赛：{{ coutime }}</span>
            </template>
            <template v-else-if="eventInfo.status == 4" v-cloak>
              <span class="info_time">赛事进行中</span>
            </template>
            <template v-else v-cloak>
              <span class="info_time">赛事已完成</span>
            </template>
            </div>
            <span class="info_time" v-if="eventInfo.matchEndDate"
              >比赛时间：{{ matchDate | dealTime }}</span
            >
            <!-- 分享code -->
            <!-- <div class="share clearfix">
              <span class="f_left">分享到：</span>
              <i
                :class="['weixin_icon f_left', { act: isshowShare }]"
                @click="showShare"
                @mouseleave="hideShare"
              ></i>
              
              <div class="code_share" v-show="isshowShare">
                <vue-qr
                  :text="shareUrl"
                  :size="111"
                  :margin="0"
                  :dotScale="1"
                  class="shareImg"
                ></vue-qr>
                <span class="txt">使用微信扫一扫</span>
              </div>
            </div> -->
          </div>
          <div
            class="handle_cz act clearfix"
            
            v-cloak
          >
            <!-- <button
              v-if="eventInfo.isCollection == true"
              class="unfollowed followed f_left"
              @click.stop="followed()"
            >
              已关注
            </button>
            <button v-else class="unfollowed f_left" @click.stop="followed()">
              +关注
            </button> -->
            <div v-if="!eventInfo.isEnroll">
<button class="apply f_left notapply"
              disabled
            >
              {{eventInfo.labels}}
            </button>
            </div>
            <div v-else>
            <button
              class="apply f_left notapply"
              v-if="eventInfo.status == -1"
              disabled
            >
              报名已关闭
            </button>
            <button
              class="apply f_left notapply"
              v-if="eventInfo.status == 0"
              disabled
            >
              报名未开始
            </button>
            <button
              class="apply f_left"
              @click="apply_submit"
              v-else-if="eventInfo.status == 1"
            >
              立即报名
            </button>
            <button
              class="apply f_left notapply"
              v-else-if="eventInfo.status == 2"
              disabled
            >
              报名已结束
            </button>
            <button
              class="apply f_left notapply"
              v-else-if="eventInfo.status == 3"
              disabled
            >
              即将开赛
            </button>
            <button
              class="apply f_left notapply"
              v-else-if="eventInfo.status == 4"
              disabled
            >
              赛事进行中
            </button>
            <button class="apply f_left notapply" v-else>赛事已完成</button>
            </div>
          </div>
        </div>
        <div class="score">
          <span class="score_num" v-if="eventInfo.score"
            >{{ score }}分</span
          >
          <span class="score_txt">{{ scoreTxt }}</span>
          <span class="lookmore" @click.stop="lookComments">查看全部评论></span>
        </div>
        <div
          class="match_types"
          v-show="userInfo != undefined "
          v-cloak
        >
          <span class="match_txt">参赛里程：</span>
          <div class="matchs">
            <ul
              class="match_lists clearfix"
              :style="
                eventType.length <= 8
                  ? 'height:auto;'
                  : isSlide
                  ? 'height:auto;'
                  : 'height:156px;overflow:hidden;'
              "
            >
              <li
                :class="[
                  'match_item f_left text_over',
                  { active: eventType_num === index },
                ]"
                v-for="(item, index) in eventType"
                :key="index"
                @click="chooseType(item, index)"
              >
              <div v-if="item.nationality=='-1'">{{ item.title }}
                 中国籍￥{{ item.chinesePrice*0.01 | dealcount }}
                 外国籍￥{{ item.foreignersPrice*0.01 | dealcount }}</div>
                 <div v-else-if="item.nationality=='2'">{{ item.title }}
                 外国籍￥{{ item.foreignersPrice*0.01 | dealcount }}</div>
                 <div v-else>{{ item.title }}
                 中国籍￥{{ item.chinesePrice*0.01 | dealcount }}</div>
              </li>
            </ul>
            <div class="handle_cz clearfix">
              <!-- <button
                v-if="eventInfo.follow"
                class="followed f_left"
                @click.stop="followed()"
              >
                已关注
              </button>
              <button v-else class="unfollowed f_left" @click.stop="followed()">
                +关注
              </button> -->
              <!-- <button
                :disabled="chooseEntry.isFull === 1"
                :class="[
                  'apply',
                  'f_left',
                  { 'apply-disabled': chooseEntry.isFull === 1 },
                ]"
                @click="apply_submit"
              >
                立即报名
              </button> -->
              <div
                class="silde f_right"
                v-show="eventType.length > 8 && !isSlide"
                @click="slideTypes"
              >
                <span class="slide_txt f_left">展开全部</span>
                <i class="slide_icon f_left"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="info_navs clearfix">
        <li
          :class="
            select_nav == index ? 'nav_item nav_act f_left' : 'nav_item f_left'
          "
          v-for="(nav, index) in info_navs"
          :key="index"
          @click="chooseNav(index)"
        >
          {{ nav }}
        </li>
      </ul>
      <div class="info_main">
        <template v-if="select_nav == 0">
          <div class="event_intro_info" >
            <!-- <m-html-panel :url.sync="eventInfo.detailUrl"></m-html-panel> -->
            <div  v-html="eventInfo.richText">{{eventInfo.richText}}</div>
          </div>
        </template>
        <template v-if="select_nav == 3">
          <div
            class="event_nav_info"
            :style="selevt_num_url ? 'height:auto;' : 'height: 900px;'"
          >
            <template v-if="eventNavs.length">
<div v-for="qa in eventNavs" :key="qa.id" style="margin-bottom:40px">
  <div class="q" style="display:flex">
<img src="../../assets/newIcon/q.png" style="display:inline-block;width:40px;height:40px"/>
<div style="font-size: 25px;font-weight: 700;margin-left:15px">{{qa.question}}</div>
  </div>
  <div class="q" style="display:flex;margin-top:10px" v-for="a in qa.answers" :key="a.id">
<img src="../../assets/newIcon/a.png" style="display:inline-block;width:30px;height:30px"/>
<div style="font-size: 20px;margin-left:15px">{{a.answer}}</div>
  </div>
</div>
              <!-- <div class="event_navs">
                <template v-if="isShow_navs">
                  <ul class="navs">
                    <li
                      :class="
                        select_num === nav.id ? 'active event_nav' : 'event_nav'
                      "
                      v-for="(nav, index) in eventNavs"
                      :key="index"
                      @click="clickEventnav(nav)"
                    >
                      {{ nav.title }}
                    </li>
                  </ul>
                  <span class="nav_icon"></span>
                </template>

                <div class="alertBox" v-if="isShowTel">
                  <span class="tel"
                    >组委会电话：{{ phoneInfo.organcommitteePhone }}</span
                  >
                  <span class="tel"
                    >工作日：{{ phoneInfo.workingStart | dealworkTime }}-{{
                      phoneInfo.workingEnd | dealworkTime
                    }}</span
                  >
                  <button class="makesure" @click="makesure">好的</button>
                </div>
              </div>
              <m-html-panel
                :url.sync="selevt_num_url"
                width="100%"
              ></m-html-panel> -->
            </template>
            <div v-else class="nolist" style="">
              <img src="../../assets/icons/eventdetail_not.png" alt="" />
              <span>该赛事还没有赛事新闻哦~</span>
            </div>
          </div>
        </template>
        <template v-if="select_nav == 1">
          <div class="lists_type2 clearfix">
            <template v-if="eventNews.length">
              <div
                class="new_item clearfix"
                v-for="(item, index) in eventNews"
                :key="index"
                @click="goNewdetail(item.id)"
              >
                <template v-if="item.image">
                  <img
                    :src="item.image[0]"
                    alt="图片资源找不到"
                    class="lists_item_img f_left"
                    style="object-fit:cover"
                  />
                </template>
                <template v-else>
                  <img
                    src=""
                    alt="图片资源找不到"
                    class="lists_item_img f_left"
                  />
                </template>
                <span class="item_right_title text_twoLine">
                  {{ item.title }}
                </span>
                <div class="attention">
                  <i class="attention_icon f_left"></i>
                  <span class="attention_num f_left">{{ item.hits }}</span>
                </div>
              </div>
              <div class="pages" v-if="totalnum != 0">
                <el-pagination
                  background
                  @current-change="pageChange"
                  layout="prev,pager,next"
                  :total="totalnum"
                >
                </el-pagination>
              </div>
            </template>
            <div class="nolist" v-else>
              <img src="../../assets/icons/eventdetail_not.png" alt="" />
              <span>该赛事还没有相关新闻哦~</span>
            </div>
          </div>
        </template>
        <template v-if="select_nav == 2">
          <Comment
            :info="commentInfo"
            :eventId="eventId"
            @commentEnd="commentEnd"
            @isLogined="isLogined"
            @pageChange="pageChange1"
          ></Comment>
        </template>
        <template v-if="select_nav == 4">
          <Ask
            :info="askInfo"
            :eventId="eventId"
            @askEnd="askEnd"
            @isLogined="isLogined"
            @pageChange="pageChange2"
          ></Ask>
        </template>
      </div>
    </div>
    <Footer></Footer>
    <el-dialog  :visible.sync="isDownloadshow" >
  <div style="display:flex">
    <div style="display:flex;flex-direction: column;justify-content: center;
align-items: center;font-size:20px">
      <div>
        扫描下载APP报名
      </div>
    <vue-qr :logoSrc="img" :text="url" :size="220" :margin="8" :dotScale = '1'></vue-qr>
     </div>
     <div style="width:50px"></div>
      <div style="display:flex;flex-direction: column;justify-content: center;
align-items: center;font-size:20px">
      <div>
        扫描打开小程序报名
      </div>
                    <!-- <vue-qr :logoSrc="img2" :text="url" :size="220" :margin="8" :dotScale = '1' style="margin-left:50px"> </vue-qr> -->
                    <img :src="img2" alt="" style="width:219px;">
                    </div>
  </div>
</el-dialog>
  </div>
</template>

<script>
import Comment from "./eventAsk/eventcomment";
import Ask from "./eventAsk/eventAsk";
import { eventDetail, update, event, Like } from "../../config/request";
import { formatDuring, getDate } from "../../config/utils";
import linkedmeLink from '../../config/linkdeme';
import vueQr from "vue-qr";
import { setTimeout } from "timers";
export default {
  components: {
    Comment,
    Ask,
    vueQr,
  },
  inject: ["reload"],
  filters: {
    // 竞赛时间截取前11个字符
    dealTime(v) {
      return v.length > 11 ? v.substr(0, 11) + "" : v;
    },
    // 若为3张图显示第1张
    dealimg(v) {
      return v.substr(0, v.indexOf("|"));
    },
    //时间截取5到10的字符
    dealTime2(v) {
      return v.substr(5, 6);
    },
    // 标题只取前15个字符
    filterStrlen(str) {
      return str.length > 15 ? str.substr(0, 15) + "..." : str;
    },
    // 新闻内容取30个字
    dealCont(v) {
      return v.length > 30 ? v.substr(0, 30) + "..." : v;
    },
    //评分保留一位小数
    dealscore(v) {
      return v.toFixed(1);
    },
    //价钱显示两位小数
    dealcount(v) {
      return v.toFixed(2);
    },
    //工作时间截取到分
    dealworkTime(v) {
      return v.substr(0, 5);
    },
    // 赛事图片处理
    dealEventimg(v) {
      if (v.indexOf("http") == 0) {
        return v;
      } else {
        return v.substr(v.indexOf("http"));
      }
    },
  },
  data() {
    return {
      userInfo: null, //用户信息
      eventId: "", //地址栏赛事id
      eventInfo: "", //详情信息
      coutime: "", //时间倒计时
      matchDate: "", //比赛时间
      eventType: [], //参赛类别
      eventType_num: "", //选择参赛类别
      entryId: "", //选择参赛类别的id
      chooseEntry: {
        isFull: 0,
      }, //选择的场次信息
      title: "2019贵州·镇宁黄果树国际半程马拉松赛",
      score: 5.0, //评分
      scoreTxt: "非常棒", //评分评价
      isfollow: false, //是否关注
      follow_index: "",
      info_navs: ["赛事简介", "赛事新闻", "赛事评价", "赛事问答"],
      isShow_navs: true,
      select_nav: 0, //默认选择赛事介绍
      iframeHeight: "",
      isShowTel: false, //是否显示组委会电话
      eventNavs: [], //赛事导航列表
      eventNews: [], //赛事新闻列表
      totalnum: 52, //赛事新闻条数
      select_num: "", //赛事导航中激活的nav
      selevt_num_url: "", //赛事导航nav对应的url
      phoneInfo: "", //组委会电话信息
      bg_show: false,
      commentInfo: "", //点评信息
      askInfo: "", //问大家信息
      shareUrl: "", //分享二维码地址
      isshowShare: false, //显示分享弹框
      isSlide: false,
      timehandle: null,
      isLoading: true,
      isDownloadshow:false,
      img:require('../../assets/public/favicon.png'),
      img2:require('../../assets/public/xcx.jpg'),
      url:''
    };
  },
  methods: {
    // 关注
    followed() {
      if (!sessionStorage.getItem("userInfo")) {
        this.isLogined();
        this.eventInfo.isCollection = false;
      } else {
        let id = this.eventId;
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        let accountId = userInfo.id;
        Like({
          type:'MATCH',
          id: id,
          // accountId: accountId,
        }).then((res) => {
          console.log(res);
          // 关注成功
          // if (res.message=='关注成功') {
          //   this.isfollow = true;
          //   this.eventInfo.isCollection = true;
          // } else if (res.code == 1 && res.objectData == 1) {
          //   //取关
          //   this.isfollow = false;
          //   this.eventInfo.isCollection = false;
          // }
        });
      }
    },
    chooseType(item, index) {
      // 选择赛事类别
      this.eventType_num = index;
      this.entryId = item.entryId;
      this.chooseEntry = item;
      // 存储选择的赛事类别
      sessionStorage.setItem("eventType", JSON.stringify(item));
    },
    //点击选择nav
    chooseNav(index) {
      console.log(index)
      this.select_nav = index;
      // 点击赛事介绍
      if (index == 0) {
      }
      // 点击赛事新闻获取新闻
      if (index == 1) {
        this.getEventnews();
      }
      
      
      //点击赛事点评
      if (index == 2) {
        this.getcommentsList();
      }
      // 点击赛事评价
      if (index == 3) {
        this.getEventnavs();
        this.select_num = "";
        // this.isShow_navs = true;
      }
      // 点击问大家
      if (index == 4) {
        this.getAsklist();
      }
    },
    // 点击组委会电话
    showTel() {
      this.isShowTel = true;
    },
    // 确认已知电话
    makesure() {
      this.isShowTel = false;
    },
    changeMobsfIframe() {
      const deviceHeight = document.body.clientHeight;
      // console.log(deviceHeight)
      this.iframeHeight = Number(deviceHeight) - 80 + "px"; // 数字是页面布局高
    },
    //赛事新闻分页改变时
    pageChange(page) {},
    // 跳到指定新闻详情页
    goNewdetail(newsId) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          articleId: newsId,
        },
      });
    },
   
    // 根据赛事id获取赛事详情
    getEventinfo() {
      let accountId = sessionStorage.getItem("userInfo")
        ? JSON.parse(sessionStorage.getItem("userInfo")).id
        : "";
      if (this.eventId) {
        eventDetail
          .info({
            id: this.eventId,
            html:true
            // module: "ssjj",
            // event: this.eventId,
            // accountId: accountId,
          })
          .then((res) => {
            this.isLoading = false;
            if (res.code == 200) {
              res.data=this.$DateFormatimg(res.data)
              res=res.data
            // console.log(res,'************************************')
            res.richText=this.$showHtml(res.richText)
            // console.log(res.richText)
              this.eventInfo = res;
               res.isEnroll = true;
						// console.log('map',item);
						var canlder = (res.state & 0XF0);
						var expire = (res.state & 0X04);
						var cancel = (res.state & 0X01);
						console.log(res.state);
						if (canlder == 16) {
							res.isEnroll = false;
                            // this.$set(res,'isEnroll',false)
						}
						if (cancel == 1 || expire == 4) {
							if (cancel == 1) {
								res.labels = "赛事已取消";
                                // this.$set(res,'lables','赛事已取消')
							} else {
								res.labels = "赛事已延期";
                                // this.$set(res,'lables','赛事已延期')
							}
						res.isEnroll = false;
                        //  this.$set(res,'isEnroll',false)
						}
              this.countdown();
              // 处理时间格式
              this.matchDate = getDate(res.matchStartDate);
              this.score = res.score.toFixed(1);
              // 处理评分文案
              if (res.score >= 1 && res.score <= 2) {
                this.scoreTxt = "待改进";
              } else if (res.score > 2 && res.score <= 3) {
                this.scoreTxt = "一般";
              } else if (res.score > 3 && res.score <= 4) {
                this.scoreTxt = "满意";
              } else {
                this.scoreTxt = "非常棒";
              }
              document.getElementById("article_tit").innerHTML =
                res.title + "-马拉松报名";
              // document.getElementById('article_key').content+=`,${res.name}`;
              // console.log(document.getElementById('article_key').content)
            }
          });
      }
    },
    // 获取赛事新闻
    getEventnews() {
      if (this.eventId) {
        eventDetail
          .news({
            match_id: this.eventId,
            rows: 1,
            size:20
          })
          .then((res) => {
            console.log(res);
            if (res.code == 200 && res.data.length) {
              let newsArr = [];
              res.data.forEach((item) => {
                // 图片和视频处理
                let imageArr = [];
                let liClass = "";
                item.image=item.coverImage
                let isVideo =
                  !item.image.indexOf("mp4") || !item.image.indexOf("flash");
                if (isVideo) {
                  // 视频
                  imageArr = item.image;
                  liClass = "news_video";
                } else {
                  // 图片
                  item.image = item.image.split("|");
                  item.image.forEach((subItem) => {
                    if (subItem != "" && subItem.indexOf("http") != -1) {
                      imageArr.push(subItem);
                    }
                  });
                  // 新闻图片3张以下的都按一张图片的页面样式展示
                  if (imageArr.length < 3) {
                    imageArr = imageArr.slice(0, 1);
                    liClass = "news_one_img";
                  } else {
                    imageArr = imageArr.slice(0, 3);
                    liClass = "news_more_img";
                  }
                }
                imageArr=this.$DateFormatimg(imageArr)
                newsArr.push({
                  id: item.id,
                  hits: item.hits,
                  image: imageArr,
                  title: item.title,
                  isVideo: isVideo,
                  liClass: liClass,
                });
              });
              this.eventNews = newsArr;
              this.totalnum = res.total;
            }
          });
      }
    },
    //获取赛事导航
    getEventnavs() {
      if (this.eventId) {
        eventDetail
          .navs(this.eventId)
          .then((res) => {
            console.log(res);
            if (res.code == 200 && res.data.length) {
              this.eventNavs = res.data;
            }
          });
      }
    },
    // 获取赛事点评列表
    getcommentsList(page) {
      let accountId = this.userInfo == undefined ? "" : this.userInfo.id;
      let pageNo = page ? page : 1;
      eventDetail
        .comment(this.eventId,{
          rows:pageNo,
size:10
        })
        .then((res) => {
          console.log(res)
          if (res.code == 200) {
            res.data.forEach(item=>{
              item.pictures=this.$DateFormatimg(item.pictures)
            })
            res.data=res.data
            this.commentInfo = res;
          }
        });
    },
    // 获取赛事问大家列表
    getAsklist(page) {
      let accountId = this.userInfo == undefined ? "" : this.userInfo.id;
      let pageNo = page ? page : 1;
      eventDetail
        .ask({
          eventId: this.eventId,
          pageNo: pageNo,
          pageSize: 10,
          accountId,
        })
        .then((res) => {
          // console.log(res)
          if (res.code == 1) {
            this.askInfo = res;
          }
        });
    },
    // 赛事点评评论点赞完成后
    commentEnd() {
      this.getcommentsList();
    },
    //问大家评论点赞后
    askEnd() {
      this.getAsklist();
    },
    //获取赛事类别
    getEventypes() {
      eventDetail
        .type(this.eventId)
        .then((res) => {
          console.log(res)
          if (res.code == 200 && res.data) {
            this.eventType = res.data;
          }
        });
    },
    // 展开赛事类别
    slideTypes() {
      this.isSlide = true;
    },
    // 点击赛事导航内的nav获取对应的url
    clickEventnav(nav) {
      this.select_num = nav.id;
      this.isShow_navs = false;
      if (nav.html && nav.phone == undefined) {
        this.isShowTel = false;
        this.selevt_num_url = nav.html;
        this.phoneInfo = "";
        this.bg_show = false;
      } else {
        this.selevt_num_url = "";
        this.phoneInfo = JSON.parse(nav.phone); //获取组委会电话信息
        this.isShowTel = true;
        this.bg_show = true;
      }
    },
    //时间倒计时
    countdown() {
       window.clearTimeout(this.timehandle)
      this.timehandle = setTimeout(() => {
        let timeCur;
        let info = this.eventInfo;
        var name = "";
        var curTime = new Date(info.currentTime).getTime()+1000;
        this.$set(info, 'currentTime',curTime)
        if (info.status == 0) {
          var times = info.enrollStartDate;
          times = Date.parse(new Date(times.replace(/-/g, "/")));//指定时间的毫秒数
          var sub = times - curTime;
          name = formatDuring(sub);
        } else if (info.status == 1) {
          var times = info.enrollEndDate;
           times = Date.parse(new Date(times.replace(/-/g, "/")));//指定时间的毫秒数
          var sub = times - curTime;
          name = formatDuring(sub);
        } else if (info.status == 3) {
          var times = info.matchStartDate;
          times = Date.parse(new Date(times.replace(/-/g, "/")));//指定时间的毫秒数
          var sub = times - curTime;
          name = formatDuring(sub);
        }
        else if (info.status == 4) {
          var times = info.matchEndDate;
          times = Date.parse(new Date(times.replace(/-/g, "/")));//指定时间的毫秒数
          var sub = times - curTime;
          name = formatDuring(sub);
        }
        timeCur = name;
        this.coutime = timeCur;
        this.countdown();
      }, 1000);
    },
    //立即报名
    apply_submit() {
      console.log(this.eventInfo);
      if(this.eventInfo.outLink!==''&&this.eventInfo.outLink){
        this.$confirm('即将打开外部报名地址，马拉松报名将无法为您提供安全保障及相关信息查询服务。', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href=this.eventInfo.outLink
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
        
      }else{
        this.isDownloadshow=true
      }
      // if (!sessionStorage.getItem("userInfo")) {
      //   this.isLogined();
      // } else {
      //   if (this.eventInfo.isNative == 1) {
      //     // 跳到第三方
      //     window.open(this.eventInfo.outUrl, "_blank");
      //   } else {
      //     // 正常报名
      //     if (this.entryId != "") {
      //       // 进入订单页 赛事id，选择类别id
      //       this.$router.push({
      //         path: "/order",
      //         query: {
      //           entryId: this.entryId,
      //           eventId: this.eventId,
      //         },
      //       });
      //     } else {
      //       this.$alert("请选择参赛类别!", "消息提示", {
      //         dangerouslyUseHTMLString: true,
      //       });
      //     }
      //   }
      // }
    },
    //查看全部评论
    lookComments() {
      this.select_nav = 2;
      setTimeout(() => {
        // 跳到赛事点评处
        var ruleTop =
          $(".info_navs")[0].offsetTop - $(".info_navs")[0].clientHeight; //滚动的位置；
        document.documentElement.scrollTop = ruleTop;
        // window.scrollTo({
        //     top: ruleTop,
        //     behavior: "smooth"
        // })
      }, 0);
    },
    // 弹出登录弹框
    isLogined() {
      // 弹出登录框
      this.$refs.Header.Login();
    },
    //点击分享出现二位码弹框
    showShare() {
      this.isshowShare = !this.isshowShare;
      this.shareUrl =
        "https://h5.marathonbm.com/#/malasmain?event=" + this.eventId;
    },
    hideShare() {
      this.isshowShare = false;
    },
    // 点评分页时
    pageChange1(page) {
      this.getcommentsList(page);
    },
    // 问大家点击分页时
    pageChange2(page) {
      this.getAsklist(page);
    },
  },
  created() {
        // 二维码下载打开app
        linkedmeLink({
            path: '', //跳转app的名字
        }).then(res => {
            this.url = res.url;
            console.log(res);
        })
        // this.loading()
    },
  mounted() {
    // 获取地址栏赛事id
    if (this.$route.query.eventId) {
      this.eventId = this.$route.query.eventId;
    }
    // 用户是登录中状态时获取赛事类别,用户信息
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.getEventypes();
    }
    // 收藏要查看详情时：定位到赛事详情的点评或者问大家
    // 若不是从收藏跳转过来的，默认选择赛事详情的赛事简介
    this.select_nav = this.$route.query.selectNav || 0;
    if (this.$route.query.selectNav) {
      this.chooseNav(this.$route.query.selectNav);
    }
    this.getEventinfo();
    this.getcommentsList();
    console.log($("viewport"));
  },
  destroyed() {
    window.clearTimeout(this.timehandle);
  },
};
</script>

<style>
@import "./style/eventDetail.css";
</style>
<style lang="less" scoped>
.apply-disabled {
  background-color: gray;
}
</style>
