<template>
    <div class="Comment_cont">
        <div class="publish clearfix">
            <img :src="userInfo.imageSrc" alt="" class="user_img f_left" v-if="userInfo!=undefined">
            <img src="../../../assets/eventdetail/config.png" alt="" class="user_img f_left" v-else>
            <input type="text" v-model="main_cont" placeholder="说两句吧..." class="publish_cont f_left" @focus="openDownload">
            <button class="apply publish_btn f_right" @click="openDownload">发布</button>
        </div>
        <div class="commentAsk_items">
            <div class="commentAsk_top clearfix">
                <i class="star_icon f_left"></i>
                <span class="items_title f_left">赛事问大家
                    <template v-if="askInfo.count">
                        ({{askInfo.count}})
                    </template>
                    <template v-else>
                        (0)
                    </template>
                </span>
            </div>
            <ul class="comments" v-if="askInfo.list">
                <li class="comments_list clearfix" 
                v-for="(comment_item,index) in askInfo.list" :key="index">
                    <img :src="comment_item.photo" v-if="comment_item.photo" alt="" class="list_img">
                    <img src="" v-else alt="" class="list_img">
                    <div class="comments_show">
                        <div class="comments_show_top">
                            <div class="list_info">
                                <span class="list_name">{{comment_item.nick}}</span>
                               <span class="list_time"> <span v-if="comment_item.address">{{comment_item.address}}</span>  {{comment_item.createDate|dealDatetime}}</span>
                            </div>
                        </div>
                        <div class="comments_show_cont">
                            <span class="content">{{comment_item.content}}</span>
                            <div class="conent_imgs clearfix" v-if="comment_item.questionImageList.length">
                                <!-- <img :src="img.image" alt="" class="img_item f_left" 
                                v-for="(img,img_index) in comment_item.questionImageList" :key="img_index"
                                @mouseenter="showImage(index,img.image)"
                                @mouseleave="hideImage()"> -->
                                <span class="img_item f_left"
                                v-for="(img,img_index) in comment_item.questionImageList" :key="img_index"
                                @mouseenter="showImage(index,img.image)"
                                @mouseleave="hideImage()"
                                :style="{backgroundImage: 'url(' + img.image + ')', backgroundSize:'cover',backgroundRepeat: 'no-repeat',backgroundPosition: '100%'}"></span>
                                <!-- 图片放大 -->
                                <div class="imgShow" v-if="showImage_index===index">
                                    <span class="middle" :style="{backgroundImage: 'url(' + largeImg + ')', backgroundSize:'cover',backgroundRepeat: 'no-repeat',backgroundPosition: '100%'}"></span>
                                </div>
                            </div>
                            <div class="caozuo clearfix"
                            @mouseenter="jubaoShow(index)"
                            @mouseleave="jubaoHide()">
                                <div class="cz_right f_right">
                                    <span class="jubao f_left"  v-if="isjubao_Show === index">
                                        <span class="txt1 f_left" @click="jubaoHandle(comment_item,1)">敏感话题</span>
                                        <span class="txt2 f_left" @click="jubaoHandle(comment_item,2)">不良内容</span>
                                    </span>
                                    <span 
                                    class="like f_left" 
                                    @click="likehandle1(comment_item,index)">
                                        <i :class="['zan_icon f_left', {'act': comment_item.praise}]"></i>
                                        {{comment_item.like}}
                                    </span>
                                    <span :class="isComment_num==index?' like f_left':'like f_left'" @click="commentHandle(index)">
                                        <i class="zan_icon reply_icon f_left"></i>
                                        评论
                                    </span>
                                </div>
                            </div>
                            <div class="reply_handle clearfix" v-show="isComment_num === index">
                                <input type="text" v-model="comment_cont" placeholder="说两句吧..." class="publish_cont f_left">
                                <button class="apply publish_btn f_left" @click="commentEnd(comment_item)">评论</button>
                            </div>
                            <div class="reply_content" 
                            v-if="comment_item.questionReplyList&&comment_item.questionReplyList.length"
                            :style="comment_item.questionReplyList.length<=6?'height:auto':lookmore_num === index?'height:auto':reply_cont_index===index?'height:544px;overflow:hidden;':'height:492px;overflow:hidden;'">
                                <div class="reply_list" v-for="(reply_item,re_index) in comment_item.questionReplyList" :key="re_index">
                                    <span class="list_cont">
                                        {{reply_item.repylNick}}
                                        <template v-if="reply_item.type == 2">
                                            <span class="huifu">回复</span>
                                            {{reply_item.followNick}}
                                        </template>
                                        ：{{reply_item.content}}
                                    </span>
                                    <span class="list_bot clearfix">
                                        <span class="f_left">{{reply_item.createDate|dealDatetime}}</span>
                                        <div class="caozuo f_right">
                                             <div class="cz_right f_right">
                                                <span class="like f_left" @click="likehandle2(reply_item)">
                                                    <i :class="['zan_icon f_left', {'act': reply_item.praise}]"></i>
                                                    {{reply_item.like}}
                                                </span>
                                                <span class="like f_left" @click="replyHandle(index,re_index)">
                                                    <i class="zan_icon reply_icon f_left"></i>
                                                    回复
                                                </span>
                                            </div>
                                        </div>
                                    </span>
                                    <div class="reply_handle clearfix"
                                    v-show="item_num===index && isReply_num===re_index">
                                        <input type="text" v-model="reply_cont" placeholder="说两句吧..." class="publish_cont f_left">
                                        <button class="apply publish_btn f_right" @click="replyEnd(reply_item)">回复</button>
                                    </div>
                                </div>
                                <div class="more_reply" 
                                v-if="comment_item.questionReplyList.length>6&&lookmore_num!==index"
                                @click="lookMorereply(comment_item,index)">
                                    <span class="more_reply_txt">查看全部</span>
                                    <span class="xia_icon"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="nolist" v-else>
                <img src="../../../assets/icons/eventdetail_not.png" alt="">
                <span>当前还没有人发布过内容哦~</span>
            </div>
            <!-- 分页 -->
            <div class="pages" v-if="askInfo.list">
                <el-pagination
                background
                @current-change="pageChange"
                layout="prev,pager,next"
                :total="askInfo.count"
                :current-page="page"
                >
                </el-pagination>
            </div>
        </div>
        <div class="dialog_cont clearfix dia_middle" v-show="isDialog">
            <span class="icon f_left"></span>
            <span class="dialog_msg f_left">{{msg}}</span>
        </div>
        <!-- 弹框下载app -->
        <Download
        :isDownloadshow="isDownloadshow"
        :url="downloadUrl"
        @closeAlert="closeAlert"></Download>
    </div>
</template>

<script>
import {eventDetail,reply,Zan} from '../../../config/request'
import linkedmeLink from '../../../config/linkdeme';
import vueQr from 'vue-qr'
import { farawayTime } from '../../../config/utils';
export default {
    props: ['info','eventId'],
    components:{
        vueQr
    },
    filters:{
        //评论展示300字
        dealconent(v) {
            return v.length>300 ? v.substr(0,300)+'' : v;
        },
         //时间显示格式
        dealDatetime(v) {
            var dateTimeStamp = Date.parse(v.replace(/-/gi,"/"));
            var minute = 1000 * 60;
            var hour = minute * 60;
            var day = hour * 24;
            var result;
            var halfamonth = day * 15;
            var month = day * 30;
            var year = month *12;
            var now = new Date().getTime();
            var diffValue = now - dateTimeStamp;
            if(diffValue < 0){
                return;
            }
            var yearC = diffValue/year;
            var monthC =diffValue/month;
            var weekC =diffValue/(7*day);
            var dayC =diffValue/day;
            var hourC =diffValue/hour;
            var minC =diffValue/minute;
            if(yearC>1){
                var year = v.split('-')[0];
                var month = v.split('-')[1];
                var date = v.split('-')[2].substr(0,2);
                var time = v.split('-')[2].substr(3);
                result =  year+'年'+month+'月'+date+'日 '+time;
            }
            else if(dayC>=4){
                var year = v.split('-')[0];
                var month = v.split('-')[1];
                var date = v.split('-')[2].substr(0,2);
                var time = v.split('-')[2].substr(3);
                result =  month+'月'+date+'日 '+time;
            }
            else if(dayC>=1){
                result=parseInt(dayC) +"天前";
            }
            else if(hourC>=1){
                result=parseInt(hourC) +"小时前";
            }
            else if(minC>1){
                result=parseInt(minC) +"分钟前";
            }else {
                result = "刚刚";
            }
            return result;
        }
    },
    computed:{
        // 点评信息
        askInfo() {
            return this.info;
        }
    },
    watch: {
        info(newValue, oldValue) {
    // 　　　　console.log(newValue)
            this.info = newValue;
    　　},
        eventId(newValue, oldValue) {
            this.eventId = newValue;
        }
    },
    data() {
        return {
            main_cont:'',//发布内容
            comment_cont:'',//评论内容
            reply_cont:'',//回复内容
            isDownloadshow:false,//是否弹出下载app弹框
            downloadUrl:'https://www.marathonbm.com/down/marathon',//下载app弹框二维码url
            isComment_num:'',//是否评论
            isReply:false,//是否回复
            isReply_num:'',//是否回复
            isComment_like:'',//评论点赞
            isComment_like_show:false,//评论点赞激活icon
            isReply_like:'',//回复点赞
            isReply_like_show:false,//回复点赞激活icon
            item_num:'',//当前点击这条评论的index
            isAllreply:false,//是否查看全部评论
            score_num:4.5,
            isDialog:false,//默认不弹框
            msg:'',//弹框信息
            userInfo:null,//用户信息
            lookmore_num:'',//查看更多下标
            isjubao_Show:'',//举报弹框显示
            timer:null,
            replayHeight:492,
            largeImg:'',//放大图片
            showImage_index:'',
            page:1,
            reply_cont_index:'',
        }
    },
    methods: {
        // 点击评论出现弹框
        commentHandle(index) {
            if(!sessionStorage.getItem('userInfo')) {
                // this.isLogined();
                // 登录弹框
                this.$emit('isLogined')
            }else {
                this.comment_cont = "";
                this.isComment_num = index;
            }
        },
        // 评论完成
        commentEnd(item) {
            if(this.comment_cont!='') {
                // 当前评论id
                let questionId = item.id;
                // 评论人id
                let replyAuthor = JSON.parse(sessionStorage.getItem('userInfo')).id;
                // 被评论人id
                let followAccount = item.accountId;
                // 被回复id
                let replyId = '';
                //地址
                let address ='';
                reply.ask_reply({
                    questionId:questionId,
                    replyAuthor:replyAuthor,
                    followAccount:followAccount,
                    replyId,
                    type:1,
                    address:'',
                    content:this.comment_cont,
                    replyType:2
                }).then(res => {
                    this.isComment_num = false;
                    this.closeDialog("评论成功!")
                    this.$emit('askEnd')
                })
            }else {
                this.closeDialog("评论内容不能为空!");
            }
        },
        //点击回复
        replyHandle(index1,index2) {
            if(!sessionStorage.getItem('userInfo')) {
                // this.isLogined();
                // 登录弹框
                this.$emit('isLogined')
            }else {
                this.reply_cont = "";
                this.item_num = index1;
                this.reply_cont_index = index1;
                this.isReply_num = index2;
                this.replayHeight=544;
            }
        },
        // 回复完成
        replyEnd(item) {
              if(this.reply_cont!='') {
                // 当前评论id
                let questionId = item.questionId;
                // 评论人id
                let replyAuthor = JSON.parse(sessionStorage.getItem('userInfo')).id;
                // 被评论人id
                let followAccount = item.replyAuthor;
                // 被回复id
                let replyId = item.id;
                //地址
                let address ='';
                reply.ask_reply({
                    questionId:questionId,
                    replyAuthor:replyAuthor,
                    followAccount:followAccount,
                    replyId:replyId,
                    address,
                    type:2,
                    content:this.reply_cont,
                    replyType:1
                }).then(res => {
                    console.log(res)
                    if(res.code == 1) {
                        this.isReply_num = false;
                        this.replayHeight = 492;
                        this.reply_cont_index = '';
                        this.$emit('askEnd')
                        this.closeDialog("回复成功!")
                    }
                   
                })
            }else {
                this.closeDialog("回复内容不能为空");
            }
        },
        // 评论点赞
        likehandle1(item,index) {
            if(!sessionStorage.getItem('userInfo')) {
                // this.isLogined();
                // 登录弹框
                this.$emit('isLogined')
            } else {
                // 主评id
                let questionId = item.id;
                //被点赞人id
                let followAccountId = item.accountId;
                // 点赞人id
                let authorId = JSON.parse(sessionStorage.getItem('userInfo')).id;
                Zan.ask({
                    questionId:questionId,
                    followAccountId:followAccountId,
                    authorId:authorId
                }).then(res => {
                    console.log(res)
                    // 点赞
                    if(res.code == 1 &&res.objectData == 2) {
                        item.praise = true;
                        item.like += 1;
                        // this.$emit('askEnd')
                        console.log('评论点赞成功')
                    }
                    else if(res.code == 1 &&res.objectData == 1) {
                        //取消点赞
                        item.praise = false;
                        item.like -= 1;
                        // this.$emit('askEnd')
                        console.log('评论取消点赞成功')
                    }
                })
            }
        },
        //回复点赞
        likehandle2(item) {
            if(!sessionStorage.getItem('userInfo')) {
                // this.isLogined();
                // 登录弹框
                this.$emit('isLogined')
            }else {
                let questionId = item.id;
                let followAccountId = item.replyAuthor
                let authorId = JSON.parse(sessionStorage.getItem('userInfo')).id;
                Zan.ask({
                    questionId:questionId,
                    followAccountId:followAccountId,
                    authorId:authorId
                }).then(res => {
                    console.log(res)
                    if(res.code == 1 && res.objectData == 2) {
                        // 点赞
                        item.praise = true;
                        item.like += 1;
                        // this.$emit('askEnd')
                        console.log('回复点赞成功')
                    } 
                    else if(res.code == 1 && res.objectData == 1) {
                        //取消点赞
                        item.praise = false;
                        item.like -= 1;
                        // this.$emit('askEnd')
                        console.log('回复取消点赞成功')
                    }
                })

            }
        },
        // 点击发表评论提示下载app
        openDownload() {
            this.isDownloadshow = true;
            // console.log( this.$route.query.id)
            // 进入赛事详情问大家
            linkedmeLink({
                id: this.eventId,
                path: 'run/malasong/ask', //跳转app的名字
            }).then(res => {
                // console.log(res)
                this.downloadUrl = res.url;
            })
        },
        // 关闭下载app弹框
        closeAlert(v) {
            this.isDownloadshow = v;
        },
         // 弹出登录弹框
        isLogined() {
            this.$store.commit('updateLogin',true)
        },
        // 小弹框出现，2秒后消失
        closeDialog(msg) {
            this.isDialog = true;
            this.msg = msg;
            this.timer = window.setTimeout(()=>{
                this.isDialog = false;
            },2000)
        },
        // 查看更多
        lookMorereply(item,index) {
            this.lookmore_num = index;
        },
         // 举报弹框显示
        jubaoShow(index) {
            this.isjubao_Show = index
        },
        // 举报弹框隐藏
        jubaoHide() {
            this.isjubao_Show = '';
        },
        //举报评论
        jubaoHandle(item,type_num) {
            if(!sessionStorage.getItem('userInfo')) {
                // this.isLogined();
                // 登录弹框
                this.$emit('isLogined')
            }else {
                // 当前评论id
                let commentId = item.id;
                let type = 1;
                let reason = type_num==1?'敏感话题':'不良内容';
                eventDetail.jubao({
                    cqId:commentId,
                    type:type,
                    reason:reason
                }).then(res =>{
                    if(res.code == 1) {
                        this.closeDialog(res.msg)
                    }
                })
            }    

        },
         // 鼠标移入展示图片大图
        showImage(index,img) {
            this.largeImg = img;
            this.showImage_index = index;
        },
        // 鼠标离开隐藏图片
        hideImage() {
            this.showImage_index = "";
        },
         // 翻页时
        pageChange(page) {
            // console.log(page)
            this.page = page;
            this.$emit('pageChange',page);
        }
    },
    mounted() {
        if(sessionStorage.getItem('userInfo')) {
            this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        }
    },
    destroyed() {
        if(this.timer) {
            window.clearTimeout(this.timer);
        }
    }
}
</script>

<style>
@import './style/eventAsk.css'
</style>
